<template>
  <div>
    <div class="deliver-plan-left">
      <div class="px-1 my-1">
        {{ $parent.direction }}
        <div class=" has-background-white" v-for="(date, index) in ($parent.direction ? list : [list[0]])" :key="index">
          <div class="is-size-7">
            <div class="has-text-centered date-heading">
              {{ date.DayTimeOut | customdate }} - {{ date.DayTimeIn | customdate }}
            </div>
            <div class="card-table">
              <div class="content">
                <table class="table is-fullwidth is-striped">
                  <tbody>
                    <tr
                      v-for="(t, idx) in ($parent.direction ? date.truck_jobs.filter(t => t.Qty.length > 0) : date.returning_jobs.filter(t => t.Qty.length > 0))"
                      :key="idx" class="has-border">
                      <td v-html="getTruckName(t.details.truck_id)"></td>
                      <td>
                        <span v-if="t.details.origin_entity == 'warehouse'">
                          {{ getWarehouseName(t.details.origin) }}
                        </span>

                        <span v-else-if="t.details.origin_entity == 'venue'">
                          {{ t.project_name }}
                        </span>
                        <span>
                          >
                        </span>
                        <span v-if="t.details.destination_entity == 'sublocation'">
                          <span v-if="t.details.destination && t.details.origin_entity != 'venue'">{{
                            getTruckName(t.details.destination)
                          }}</span>
                          <span v-if="$parent.direction">This Venue</span>
                        </span>
                      </td>
                      <td>
                        <ul class="list">
                          <li v-for="(m, index) in t.Qty.filter(m => m.amount != m.loaded_amount)" :key="index"
                            class="has-text-missing">
                            {{ m.Caption }} <i>{{ m.amount - m.loaded_amount }}</i>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <i v-if="t.status_id == 1" class="fa fa-truck is-warning is-pulled-right  mt-1"></i>
                        <i v-if="t.status_id == 2" class="fas fa-check is-pulled-right has-text-success mt-1"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ["list"],
  methods: {
    getTruckName(t) {
      let name = ""
      this.$parent.warehouses_parameters.forEach(e => {
        if (parseInt(e.id) == parseInt(t)) {
          let a = JSON.parse(e.form_value)
          name = '<b>' + a[0].value + '</b> ' + e.data.name
        }

      })
      return name
    },

    getWarehouseName(id) {
      let w = {}
      let warehouse_list = JSON.parse(localStorage.warehouses)
      warehouse_list.map(e => {
        if (e.Id == id)
          w = e
      })
      return w.Caption
    },
  },
  computed: {
    trucks() {
      console.log('list', this.list)
      if (this.list.length)
        return this.list.map(i => {
          return i.truck_jobs.map(tj => {
            return tj.details.truck_id
          })
        })
      else return []
    }
  }
}
</script>

<style scoped>
.has-text-missing {
  color: #efffbd !important;
}

.deliver-plan-left {
  background: white;
}

.test {
  background-color: #eff5fb;
  color: #296fa8;
  font-size: 13px;
  margin-bottom: 3px;
  margin-top: 8px;
}

.date-heading {
  background-color: #eff5fb;
  color: #296fa8;
  font-size: 13px;
  margin-bottom: 3px;
  margin-top: 8px;
}

.table td,
.table th {
  padding: 0.4em 0.4em;
}

.list {
  list-style: none;
  margin: 0 !important;
}
</style>

